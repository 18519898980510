import GreenBinMark from '../assets/icons/GreenBinMark.png'
import linkedinIcon from '../assets/icons/fcfebdfae12a44f993a94aeed8e2e66b.webp'
import facebookIcon from '../assets/icons/aebe5b6fd55f471a936c72ff2c8289d7.webp'
import igIcon from '../assets/icons/40898a93cfff4578b1779073137eb1b4.webp'

import img1 from '../assets/images/1.webp'
import img2 from '../assets/images/2.webp'
import img3 from '../assets/images/3.webp'

import story1 from '../assets/images/feedback1.webp'
import story2 from '../assets/images/feedback2.webp'
import story3 from '../assets/images/feedback3.webp'

import prsn1 from '../assets/images/person1.webp'
import prsn4 from '../assets/images/person4.webp'
import serg from '../assets/images/Serg.jpeg'
import olena from '../assets/images/Olena.jpeg'
import andrew from '../assets/images/Andrew.jpeg'
import aplleQr from '../assets/images/Apple scan.png'
import appleLink from '../assets/icons/app-store-vector-icon-25.webp'
import googleLink from '../assets/icons/app-store-vector-icon-15.webp'

import forbes from '../assets/icons/5847e9aacef1014c0b5e4828.webp'
import mamstartup from '../assets/icons/mamstartup.webp'
import ictv from '../assets/icons/ictv.webp'
import pulsbiznesu from '../assets/icons/pulsbiznesu.webp'
import parp from '../assets/icons/parp-ue-en.jpg'
import './Landing.css'

import androidSvg from '../assets/icons/android.png'

import {useEffect, useState} from "react";
import axios from "axios";
import { QRCode } from 'react-qrcode-logo';

const b2bUrl = process.env.REACT_APP_B2B_URL
const apiUrl = process.env.REACT_APP_API_BASE
const resourcesBase = process.env.REACT_APP_RESOURCES_BASE

const Landing = (props) => {

    const [androidQRUrl, setAndroidQRUrl] = useState('')

    useEffect(() => {
        (async () => {
            const resp = await axios.get(`${apiUrl}/apks`)
            setAndroidQRUrl(`${resourcesBase}${resp.data.resource.originalUrl}`)
        })()
    }, [])

    return <>
        <header className="header">
            <nav>
                <ul className="nav">
                    <li className="nav-item">
                        <a href="" className="nav-item_logo">
                            <img src={GreenBinMark} className="nav-item_img--logo" alt={'logo'}/>
                            <span>returnable packaging system</span>
                        </a>
                    </li>
                    <li>
                        <a className="nav-item_btn" href={b2bUrl} target='_blank'>Registration</a>
                    </li>
                    <li>
                        <a className="nav-item_pplink" href="/privacy" target='_blank'>Privacy Policy</a>
                    </li>

                    <div className="navigation">
                        <li>
                            <a href="https://www.linkedin.com/company/greenbin-app/">
                                <img src={linkedinIcon}
                                     className="nav-item__img" alt="linkedin icon" srcSet=""/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/greenbinapp/">
                                <img src={facebookIcon}
                                     className="nav-item__img" alt="Facebook icon" srcSet=""/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/greenbin.app/">
                                <img src={igIcon}
                                     className="nav-item__img" alt="Instagram icon" srcSet=""/>
                            </a>
                        </li>
                        <div className='link-container'>
                            <a href='https://apps.apple.com/us/app/greenbin-app/id1621503785'><img src={appleLink}/></a>
                            <a href={`${androidQRUrl}`}><img
                                src={googleLink}/></a>
                        </div>
                        <div className='qr-container'>
                            <img className='qr' src={aplleQr}/>
                            <QRCode
                                value={androidQRUrl}
                                size={100}
                                logoImage={androidSvg}
                                logoHeight={24}
                                logoWidth={24}
                            />
                        </div>
                    </div>
                </ul>
            </nav>
            <div className="header-page">
                <iframe className="header-page_video"
                        width="326.025" height="579.6"
                        src="https://www.youtube.com/embed/_tbQsg3c5QI" loading="lazy"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                </iframe>
            </div>
        </header>

        <main className="main">
            <section className="section how-it-works">
                <h2 className="section_header">How it works</h2>
                <div className="wrapper">
                    <ul className="box">
                        <li className="box-item">
                            <div className="box-item_header">
                                <h3>
                                    1
                                    <br/>
                                    Stick our qr-code near barcode and scan
                                </h3>
                                <picture>
                                    <img src={img1} alt="Stick our qr-code near barcode and scan"
                                         srcSet="" loading="lazy"/>
                                </picture>
                            </div>
                            <span>Pay attention! You need QR-code stickers* to use this app</span>
                        </li>
                        <li className="box-item">
                            <div className="box-item_header">
                                <h3>
                                    2
                                    <br/>
                                    Bring the bottles to the supermarket
                                </h3>
                                <picture>

                                    <img src={img2} alt="Bring the bottles to the supermarket"
                                         srcSet="" loading="lazy"/>

                                </picture>
                            </div>
                            <span>According to the Greenbin.app map</span>
                        </li>
                        <li className="box-item">
                            <div className="box-item_header">
                                <h3>
                                    3
                                    <br/>
                                    Get Reward
                                </h3>
                                <picture>
                                    <img src={img3} alt="Get Reward" srcSet="" loading="lazy"/>
                                </picture>
                            </div>
                            <span>Exchange the points for goods and services in the Greenbin.app</span>
                        </li>
                    </ul>
                </div>
            </section>

            <section className="section our-team">
                <h2 className="section_header">Our team</h2>
                <div className="wrapper">
                    <ul className="box">
                        <li className="box-item">
                            <img src={prsn1} alt="DIMITRIY KUKHAREV" srcSet=""
                                 loading="lazy"/>
                            <div className="box-item_header">
                                <h4>
                                    DIMITRIY<br/> KUKHAREV
                                </h4>
                            </div>
                            <a href="https://www.linkedin.com/in/dimitriy-kukharev-6b163652/">
                                <img src={linkedinIcon}
                                     className="box-item__img--soc" alt="linkedin icon" srcSet=""/>
                            </a>
                            <span>Founder &amp; CEO</span>
                        </li>
                        <li className="box-item">
                            <img src={serg} alt="SERGEY KUKHAREV" srcSet=""
                                 loading="lazy"/>
                            <div className="box-item_header">
                                <h4>
                                    SERGEY<br/> KUKHAREV
                                </h4>
                            </div>
                            <a>
                                <img src={linkedinIcon}
                                     className="box-item__img--soc" alt="linkedin icon" srcSet=""/>
                            </a>
                            <span>Angel Investor &amp; CPO</span>
                        </li>
                        <li className="box-item">
                            <img src={olena} alt="Olena Gudzovska" srcSet=""
                                 loading="lazy"/>
                            <div className="box-item_header">
                                <h4>
                                    OLENA <br/> KLYMCHUCK
                                </h4>
                            </div>
                            <a>
                                <img src={linkedinIcon}
                                     className="box-item__img--soc" alt="linkedin icon" srcSet=""/>
                            </a>
                            <span>Ukrainian Ambassador</span>
                        </li>
                        <li className="box-item">
                            <img src={andrew} alt="Andrew Frankland" srcSet=""
                                 loading="lazy"/>
                            <h4>
                                ANDREW <br/> FRANKLAND
                            </h4>
                            <a>
                                <img src={linkedinIcon}
                                     className="box-item__img--soc" alt="linkedin icon" srcSet=""/>
                            </a>
                            <span>Business Developer</span>
                        </li>
                        <li className="box-item">
                            <img src={prsn4} alt="IGOR OVCHARENKO" srcSet=""
                                 loading="lazy"/>
                            <h4>
                                IGOR<br/> OVCHARENKO
                            </h4>
                            <a href="https://www.linkedin.com/in/ihorovcharenko/">
                                <img src={linkedinIcon}
                                     className="box-item__img--soc" alt="linkedin icon" srcSet=""/>
                            </a>
                            <span>Angel Investor</span>
                        </li>
                    </ul>
                </div>
            </section>

            <section className="section feedback">
                <div className="wrapper">
                    <ul className="box">
                        <li className="box-item item-1"
                            style={{background: `url(${story1})`, backgroundSize: 'contain'}}>
                            <div className="container">
                                <h4>Aleksandr Bondar</h4>
                                <span>My son and I have done the first contribution to the environmental cleanup, from plastic and we invite everyone to collect and sort plastic bottles. Let's make the world cleaner together. A very useful app!</span>
                            </div>
                        </li>
                        <li className="box-item item-2"
                            style={{background: `url(${story2})`, backgroundSize: 'contain'}}>
                            <div className="container">
                                <h4>Olena Trufen-Vityshak</h4>
                                <span>A super idea and the implementation. Amazing perspectives of growth) recommended</span>
                            </div>
                        </li>
                        <li className="box-item item-3"
                            style={{background: `url(${story3})`, backgroundSize: 'contain'}}>
                            <div className="container">
                                <h4>Yana Parfentieva</h4>
                                <span>I think the idea is great, I started using it when I was in Poland and it helps me to get a habit of recycling. And I can donate for different NGOs</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>

            <section className="section in-media">
                <h2 className="section_header">We in the media</h2>
                <div className="wrapper">
                    <ul className="box">
                        <li className="box-item">
                            <a href="https://www.forbes.pl/biznes/greenbinapp-polski-start-up-zalozony-przez-olene-sydorenko-zapewni-transparentnosc/n0wn38n">
                                <img src={forbes} alt="Forbes" srcSet=""
                                     loading="lazy"/>

                            </a>
                        </li>
                        <li className="box-item">
                            <a href="http://https://mamstartup.pl/polska-bedzie-liderem-w-recyklingu-w-ciagu-najblizszych-1-2-lat-greenbin-app-ma-w-tym-pomoc/">
                                <img src={mamstartup} alt="Mamstartup" srcSet=""
                                     loading="lazy"/>
                            </a>
                        </li>
                        <li className="box-item">
                            <a href="https://www.pb.pl/innowatorzy-zrownowazonego-rozwoju-990660">
                                <img src={pulsbiznesu} alt="Puls Biznesu" srcSet=""
                                     loading="lazy"/>
                            </a>
                        </li>
                        <li className="box-item">
                            <a href="https://www.youtube.com/watch?v=yjJ0SNOutsE&amp;feature=youtu.be">
                                <img src={ictv} alt="ICTV" srcSet="" loading="lazy"/>
                            </a>
                        </li>
                    </ul>
                </div>
            </section>

            <section className="section our-key-partners">
                <h2 className="section_header">Our Key Partners</h2>
                <div className="wrapper">
                    <div className="our-key-partners-desktop">
                        <img src={parp} alt="Poland prize" srcSet="" loading="lazy"/>
                    </div>

                    <div className="our-key-partners-mob">
                        <img src={parp} alt="Poland prize" srcSet=""
                             loading="lazy"/>
                    </div>
                </div>
            </section>

            <section className="section contact-us">
                <h3 className="contact-us_header">If you have any questions, <br/> don't hesitate, CONTACT US</h3>
                <div className="wrapper">
                    <a href="mailto:hello@greenbin.app">hello@greenbin.app</a>
                </div>
            </section>
        </main>
    </>
}

export default Landing;